import { CommonModule } from '@angular/common';
import { Component, computed, effect, input, output, signal, viewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { SensorId } from '@features/files-explorer/shared/interface/sensor-id.interface';
import { TrajectoryService } from '@features/sensor-trajectories/services/trajectory/trajectory.service';
import { Trajectory } from '@features/sensor-trajectories/shared/interfaces/trajectory.interface';
import { TrendType } from '@features/sensor-trajectories/shared/interfaces/trajectory-trend.interface';
import { sensorTrajectoriesFeature } from '@features/sensor-trajectories/shared/store/sensor-trajectories.feature';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog.service';
import { parseProcessingState } from '@shared/interfaces/processing-status';

import { TrendMiniatureComponent } from '../trend-miniature/trend-miniature.component';

type TrendSelection = Record<TrendType, Set<string>>;

@Component({
  selector: 'app-sensor-trajectories',
  standalone: true,
  imports: [CommonModule, MaterialModule, ScrollableBorderedContainerComponent, TrendMiniatureComponent],
  providers: [TrajectoryService, DialogService],
  templateUrl: './sensor-trajectories.component.html',
  styles: ``,
})
export class SensorTrajectoriesComponent {
  sensorId = input.required<SensorId>();
  navigateToGraph = output();
  matSort = viewChild(MatSort);

  canRecompute = this.store.selectSignal(authFeature.selectCanRecompute);

  dataSource = computed(() => {
    const trajectories = this.trajectories();
    if (trajectories) {
      const dataSource = new MatTableDataSource(trajectories);
      dataSource.paginator = this.paginator() ?? null;
      dataSource.sort = this.matSort() ?? null;
      dataSource.sortData = this.sortData;
      return dataSource;
    }
    return undefined;
  });
  columns = ['name', 'detectionPercentage', 'frequency', 'filler'];

  hasSelected = computed(() => this.nSelected() > 0);
  partiallySelected = computed(() => this.hasSelected() && this.nSelected() !== this.trajectories()?.length);
  allSelected = computed(() => this.nSelected() === this.trajectories()?.length);

  private trajectories = this.trajectoryService.selectTrajectories();
  private paginator = viewChild(MatPaginator);
  private nameToTrajectory = computed<Record<string, Trajectory>>(() =>
    Object.fromEntries((this.trajectories() ?? []).map(trajectory => [trajectory.name, trajectory]))
  );
  private selected = signal<TrendSelection>({ [TrendType.Trajectory]: new Set<string>() });
  private nSelected = computed(() => Object.values(this.selected()).reduce((s, cur) => s + cur.size, 0));

  public sensorTrajectoriesNextComputationDate = this.store.selectSignal(
    sensorTrajectoriesFeature.selectNextComputationDate
  );
  private sensorTrajectoriesStatus = this.store.selectSignal(sensorTrajectoriesFeature.selectStatus);
  public statusItemDescription = computed(() => parseProcessingState(this.sensorTrajectoriesStatus()));

  constructor(
    private trajectoryService: TrajectoryService,
    private store: Store,
    private dialogService: DialogService
  ) {
    effect(
      () => {
        this.refresh();
      },
      { allowSignalWrites: true }
    );
  }

  toggle(trendType: TrendType, name: string) {
    this.selected.update(selected => {
      const set = selected[trendType];
      if (!set.delete(name)) {
        set.add(name);
      }
      return { ...selected, [trendType]: set };
    });
  }

  isSelected(trendType: TrendType, name: string) {
    return this.selected()[trendType].has(name);
  }

  refresh() {
    this.trajectoryService.fetchTrajectories(this.sensorId());
  }

  async recompute() {
    const recompute = await this.dialogService.confirm({
      title: 'Recompute Trajectories',
      question: 'Do you want to recompute all trajectories? It might take some time.',
    });

    if (recompute) {
      this.trajectoryService.setCurrentSensorTrackingAsDirty(this.sensorId());
    }
  }

  visualize() {
    const nameToTrajectory = this.nameToTrajectory();
    this.selected()[TrendType.Trajectory].forEach(name =>
      this.trajectoryService.visualizeFrequency(nameToTrajectory[name])
    );
    this.unselectAll();
    this.navigateToGraph.emit();
  }

  unselectAll() {
    this.selected.set({ [TrendType.Trajectory]: new Set() });
  }

  checkFrequencyUpdate(checked: boolean) {
    if (checked) {
      this.selectAll();
    } else {
      this.unselectAll();
    }
  }

  private sortData(data: Trajectory[], sort: MatSort): Trajectory[] {
    const sortedData = [...data];
    if (sort.active === 'name' && sort.direction === 'asc') {
      return sortedData.sort((a, b) => a.frequency - b.frequency);
    }
    if (sort.active === 'name' && sort.direction === 'desc') {
      return sortedData.sort((a, b) => b.frequency - a.frequency);
    }
    if (sort.active === 'detectionPercentage' && sort.direction === 'asc') {
      return sortedData.sort((a, b) => a.detectionPercentage - b.detectionPercentage);
    }
    if (sort.active === 'detectionPercentage' && sort.direction === 'desc') {
      return sortedData.sort((a, b) => b.detectionPercentage - a.detectionPercentage);
    }
    return sortedData;
  }

  TrendType = TrendType;

  private selectAll() {
    this.selected.set({ [TrendType.Trajectory]: new Set(this.trajectories()?.map(t => t.name)) });
  }
}
